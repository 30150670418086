<template>
    <div>
        <div
            v-if="statusLoading"
            class="layout justify-center py-5">
            <loader label="Checking deployment status..." />
        </div>
        <template v-else>
            <div>
                <v-alert
                    :value="error"
                    type="error"
                    class="mb-4">
                    {{ error }}
                </v-alert>

                <h2>Play Review &amp; Deployment</h2>
                <p
                    v-if="onboardingValid"
                    class="mb-4">
                    You’re to the finish line 🏆 and are ready to deploy some plays.
                    Please review the settings below before moving forward.
                </p>
                <div
                    v-else
                    class="mb-4">
                    <p>
                        You're not quite ready to deploy this play.  Please review
                        the missing dependencies below and revisit the outstanding steps
                        as there's likely additional actions you still need to take then
                        we can get rolling 🚗!
                    </p>
                    <incomplete-features class="mb-4" />
                </div>

                <v-alert
                    :value="!onboardingUserCampaignsValid"
                    type="error"
                    class="mb-4">
                    Some of your campaigns aren't valid.  Please go back to that step and verify them.
                </v-alert>
                <v-alert
                    :value="!onboardingUserAdSetsValid"
                    type="error"
                    class="mb-4">
                    Some of your ad sets aren't valid.  Please go back to that step and verify them.
                </v-alert>
                <v-alert
                    :value="!onboardingUserAdCreativesValid"
                    type="error"
                    class="mb-4">
                    Some of your ad creatives aren't valid.  Please go back to that step and verify them.
                </v-alert>

                <v-alert
                    :value="true"
                    type="error"
                    class="mb-4">
                    <template>
                        <p>
                            Meta is changing the way your offline data will be used for attribution by migrating our pixels to a dataset.
                            This dataset will be used for both web and offline data.
                            Because of this change, you will need to add the new dataset for Offline tracking to your Ads.
                            At this point in time, this is a manual change that needs to take place at the ad level in your Ads Manager.
                            It will not be set up automatically upon deployment of this playbook.
                        </p>
                        <p>
                            Once this playbook deploys, simply go into your ad account, select all of your ads so that you can edit them all at once,
                            and then under the Tracking section of the ad under Offline Events click on Edit Tracked Offline Event Sets.
                            Here you should select your dataset for offline tracking, which has the same name and id as your pixel.
                            Then save and review and publish.
                        </p>
                        <p>
                            You can follow this <a
                                href="https://www.loom.com/share/0b070f4d1b574351b6a50fada9d4669b"
                                target="_blank">video tutorial here</a>
                        </p>
                        <p>
                            Please reach out to <a
                                href="#"
                                @click.prevent="$store.dispatch('createOnboardingSupportTicket', 'Play Review & Deployment')">Support</a> if you need additional assistance.
                        </p>
                    </template>
                </v-alert>

                <h3 class="mb-4">
                    Targeting &amp; Budgets
                </h3>

                <campaign-tree class="mb-5" />

                <div class="mb-5">
                    <h3>Notifications</h3>
                    <p>Play deployment can take a while.  Would you like us to send a notification when the play has been deployed?</p>
                    <v-checkbox
                        v-model="sendNotifications"
                        class="styled-checkbox"
                        color="primary">
                        <template #label>
                            <span class="mr-1">Send notification on completion to:</span>
                            <notification-emails v-model="emails" />
                        </template>
                    </v-checkbox>
                </div>
            </div>
            <div class="layout align-center">
                <back-button @click="$store.dispatch('goToPreviousStep')" />
                <div class="ml-auto" />
                <a
                    v-if="!onboardingValid"
                    href="#"
                    class="mr-3"
                    @click.prevent="$store.dispatch('exitOnboarding')">
                    Save &amp; Exit
                </a>
                <!-- <styled-button
                    v-if="userIsAdmin"
                    :disabled="!onboardingValid"
                    :loading="newLoadingPlayDeploy"
                    :red="true"
                    class="mr-3"
                    @click="newDeployPlay">
                    Admin Play Deployment 🚀
                </styled-button> -->
                <styled-button
                    :disabled="!onboardingValid"
                    :loading="loadingPlayDeploy"
                    @click="deployPlay">
                    DEPLOY PLAY 🚀
                </styled-button>
            </div>
        </template>
    </div>
</template>

<script>
import Loader from '@/components/globals/Loader';
import NotificationEmails from '@/components/globals/NotificationEmails';
import CampaignTree from '@/components/onboarding/components/CampaignTree';
import IncompleteFeatures from '@/components/onboarding/components/IncompleteFeatures';
import BackButton from '@/components/globals/BackButton';
import StyledButton from '@/components/globals/StyledButton';
import { mapState, mapGetters } from 'vuex';

export default {
    name: 'OnboardingPlayReview',
    components: {
        Loader,
        CampaignTree,
        IncompleteFeatures,
        BackButton,
        NotificationEmails,
        StyledButton,
    },
    data() {
        return {
            sendNotifications: true,
            loadingPlayDeploy: false,
            newLoadingPlayDeploy: false,
            emails: [],
            error: null
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer,
            playId: (state) => state.onboarding.playId,
            play: (state) => state.onboarding.play,
            onboardingData: (state) => state.onboarding.data,
            statusLoading: (state) => state.onboarding.statusLoading,
        }),
        ...mapGetters([
            'playStatus',
            'dailyBudgetTotal',
            'monthlyBudgetTotal',
            'onboardingUserCampaignsValid',
            'onboardingUserAdSetsValid',
            'onboardingUserAdCreativesValid',
            'onboardingUserCampaignTree',
            'onboardingValid',
            'dealerFeatures',
            'userIsAdmin'
        ]),
        completeFeatureStatus() {
            return this.playStatus.filter(feature => feature.status === 'complete');
        },
        budgetAllocations() {
            return this.onboardingData.campaigns.map(campaign => {
                return {
                    campaign: campaign.remote_name,
                    allocation: campaign.budget
                };
            });
        },
        radiusAllocations() {
            return this.onboardingData.adSets.map(adSet => {
                return {
                    ad_set: adSet.remote_name,
                    allocation: adSet.radius
                };
            });
        },
        landingPageUrls() {
            return this.onboardingData?.adCreatives?.map(adCreative => {
                return {
                    ad_creative: adCreative.remote_name,
                    landing_page_url: adCreative.landing_page_url
                };
            });
        },
    },
    activated() {
        // Force a status update on all features to ensure we have a fresh state
        // also ensure that we're re-setting the existing features in case a
        // state has changed
        this.$store.dispatch('forceUpdateOnboardingStatus', {
            updateExisting: true
        });
    },
    methods: {
        async deployPlay() {
            try {
                this.error = null;
                this.loadingPlayDeploy = true;

                const data = {
                    activate_pusher: true,
                    play_id: this.playId,
                    budget_allocations: this.budgetAllocations,
                    radius_allocations: this.radiusAllocations,
                    landing_page_urls: this.landingPageUrls,
                    budget_type: 'daily',
                    // @todo determine how to handle issue where ads+ PBs include ads PBs
                    skip_validation: true
                };

                // If there's emails add them in
                if (this.sendNotifications && this.emails.length) {
                    data.email_to = this.emails;
                }

                const response = await this.$http.post(`/dealers/${this.currentDealer.id}/build-ads`, data);

                this.$store.dispatch('completeOnboarding', {
                    dealerPlayId: response.data.data.id
                });
            } catch (error) {
                // @todo map out actual errors
                console.error('Error initiating play deployment', error);
                this.error = 'There was an error initiaing this play deployment.  Please try again or contact support if the problem continues';
            } finally {
                this.loadingPlayDeploy = false;
            }
        },
        async newDeployPlay() {
            try {
                this.error = null;
                this.newLoadingPlayDeploy = true;
                const data = {
                    activate_pusher: true,
                    play_id: this.playId,
                    budget_allocations: this.budgetAllocations,
                    radius_allocations: this.radiusAllocations,
                    budget_type: 'daily',
                    // @todo determine how to handle issue where ads+ PBs include ads PBs
                    skip_validation: true,
                };
                // If there's emails add them in
                if (this.sendNotifications && this.emails.length) {
                    data.email_to = this.emails;
                }

                const response = await this.$http.post(`/dealers/${this.currentDealer.id}/build-facebook-play`, data);

                this.$store.dispatch('completeOnboarding', {
                    dealerPlayId: response.data.data.id
                });


            } catch (error) {
                // @todo map out actual errors
                console.error('Error initiating new play deployment', error);
                this.error = 'There was an error initiaing this new play deployment.  Please try again or contact support if the problem continues';
            } finally {
                this.newLoadingPlayDeploy = false;
            }
        }
    }
};
</script>
